import arrowDownWhite from "@/assets/svg/Arrow-down-white.svg";
import arrowDown from "@/assets/svg/Arrow-down.svg";
import arrowLeftWhite from "@/assets/svg/Arrow-left-white.svg";
import arrowLeft from "@/assets/svg/Arrow-left.svg";
import arrowRightWhite from "@/assets/svg/Arrow-right-white.svg";
import arrowRight from "@/assets/svg/Arrow-right.svg";
import arrowUpWhite from "@/assets/svg/Arrow-up-white.svg";
import arrowUp from "@/assets/svg/Arrow-up.svg";
import bellWhite from "@/assets/svg/Bell-white.svg";
import bell from "@/assets/svg/Bell.svg";
import chevronDownWhite from "@/assets/svg/Chevron-down-white.svg";
import chevronDown from "@/assets/svg/Chevron-down.svg";
import chevronLeftWhite from "@/assets/svg/Chevron-left-white.svg";
import chevronLeft from "@/assets/svg/Chevron-left.svg";
import chevronRightWhite from "@/assets/svg/Chevron-right-white.svg";
import chevronRight from "@/assets/svg/Chevron-right.svg";
import chevronUpWhite from "@/assets/svg/Chevron-up-white.svg";
import chevronUp from "@/assets/svg/Chevron-up.svg";
import copyWhite from "@/assets/svg/Copy-white.svg";
import copy from "@/assets/svg/Copy.svg";
import crossWhite from "@/assets/svg/Cross-white.svg";
import cross from "@/assets/svg/Cross.svg";
import downloadWhite from "@/assets/svg/Download-white.svg";
import download from "@/assets/svg/Download.svg";
import equipmentWhite from "@/assets/svg/Equipment-white.svg";
import equipment from "@/assets/svg/Equipment.svg";
import externalWhite from "@/assets/svg/External-white.svg";
import external from "@/assets/svg/External.svg";
import facebookWhite from "@/assets/svg/Facebook-white.svg";
import facebook from "@/assets/svg/Facebook.svg";
import globeWhite from "@/assets/svg/Globe-white.svg";
import globe from "@/assets/svg/Globe.svg";
import hamburgerWhite from "@/assets/svg/Hamburger-white.svg";
import hamburger from "@/assets/svg/Hamburger.svg";
import homeWhite from "@/assets/svg/Home-white.svg";
import home from "@/assets/svg/Home.svg";
import instagramWhite from "@/assets/svg/Instagram-white.svg";
import instagram from "@/assets/svg/Instagram.svg";
import linkedinWhite from "@/assets/svg/LinkedIn-white.svg";
import linkedin from "@/assets/svg/LinkedIn.svg";
import miniMenuWhite from "@/assets/svg/Mini-menu-white.svg";
import miniMenu from "@/assets/svg/Mini-menu.svg";
import padlock from "@/assets/svg/Padlock.svg";
import printWhite from "@/assets/svg/Print-white.svg";
import print from "@/assets/svg/Print.svg";
import questionMarkWhite from "@/assets/svg/Question-mark-white.svg";
import questionMark from "@/assets/svg/Question-mark.svg";
import saveWhite from "@/assets/svg/Save-white.svg";
import save from "@/assets/svg/Save.svg";
import searchWhite from "@/assets/svg/Search-white.svg";
import search from "@/assets/svg/Search.svg";
import sendWhite from "@/assets/svg/Send-white.svg";
import send from "@/assets/svg/Send.svg";
import shareWhite from "@/assets/svg/Share-white.svg";
import share from "@/assets/svg/Share.svg";
import sidebarWhite from "@/assets/svg/Sidebar-white.svg";
import sidebar from "@/assets/svg/Sidebar.svg";
import starWhite from "@/assets/svg/Star-filled-white.svg";
import star from "@/assets/svg/Star-filled.svg";
import tickWhite from "@/assets/svg/Tick-white.svg";
import tick from "@/assets/svg/Tick.svg";
import twitterWhite from "@/assets/svg/Twitter-white.svg";
import twitter from "@/assets/svg/Twitter.svg";
import warningWhite from "@/assets/svg/Warning-white.svg";
import warning from "@/assets/svg/Warning.svg";
import acornWhite from "@/assets/svg/acorn-white.svg";
import acorn from "@/assets/svg/acorn.svg";
import aiLesson from "@/assets/svg/ai-lesson.svg";
import reloadWhite from "@/assets/svg/reload-white.svg";
import reload from "@/assets/svg/reload.svg";
import uploadWhite from "@/assets/svg/upload-white.svg";
import upload from "@/assets/svg/upload.svg";

import type { IconName } from "./types";

type SVGImport = {
  src: string;
  height: number;
  width: number;
  blurDataURL: string;
  blurWidth: number;
  blurHeight: number;
};

export const svgs: Record<IconName, SVGImport> = {
  "arrow-left": arrowLeft as SVGImport,
  "arrow-right": arrowRight as SVGImport,
  "arrow-up": arrowUp as SVGImport,
  "arrow-down": arrowDown as SVGImport,
  bell: bell as SVGImport,
  "chevron-left": chevronLeft as SVGImport,
  "chevron-right": chevronRight as SVGImport,
  "chevron-up": chevronUp as SVGImport,
  "chevron-down": chevronDown as SVGImport,
  cross: cross as SVGImport,
  copy: copy as SVGImport,
  download: download as SVGImport,
  upload: upload as SVGImport,
  "upload-white": uploadWhite as SVGImport,
  external: external as SVGImport,
  globe: globe as SVGImport,
  hamburger: hamburger as SVGImport,
  home: home as SVGImport,
  "mini-menu": miniMenu as SVGImport,
  print: print as SVGImport,
  save: save as SVGImport,
  search: search as SVGImport,
  send: send as SVGImport,
  share: share as SVGImport,
  star: star as SVGImport,
  tick: tick as SVGImport,
  warning: warning as SVGImport,
  facebook: facebook as SVGImport,
  twitter: twitter as SVGImport,
  linkedin: linkedin as SVGImport,
  instagram: instagram as SVGImport,
  reload: reload as SVGImport,
  equipment: equipment as SVGImport,
  "equipment-white": equipmentWhite as SVGImport,
  "reload-white": reloadWhite as SVGImport,
  "arrow-left-white": arrowLeftWhite as SVGImport,
  "arrow-right-white": arrowRightWhite as SVGImport,
  "arrow-up-white": arrowUpWhite as SVGImport,
  "arrow-down-white": arrowDownWhite as SVGImport,
  "bell-white": bellWhite as SVGImport,
  "chevron-left-white": chevronLeftWhite as SVGImport,
  "chevron-right-white": chevronRightWhite as SVGImport,
  "chevron-up-white": chevronUpWhite as SVGImport,
  "chevron-down-white": chevronDownWhite as SVGImport,
  "cross-white": crossWhite as SVGImport,
  "copy-white": copyWhite as SVGImport,
  "download-white": downloadWhite as SVGImport,
  "external-white": externalWhite as SVGImport,
  "globe-white": globeWhite as SVGImport,
  "hamburger-white": hamburgerWhite as SVGImport,
  "home-white": homeWhite as SVGImport,
  "mini-menu-white": miniMenuWhite as SVGImport,
  "print-white": printWhite as SVGImport,
  "save-white": saveWhite as SVGImport,
  "search-white": searchWhite as SVGImport,
  "send-white": sendWhite as SVGImport,
  "share-white": shareWhite as SVGImport,
  "star-white": starWhite as SVGImport,
  "tick-white": tickWhite as SVGImport,
  "warning-white": warningWhite as SVGImport,
  "facebook-white": facebookWhite as SVGImport,
  "twitter-white": twitterWhite as SVGImport,
  "linkedin-white": linkedinWhite as SVGImport,
  "instagram-white": instagramWhite as SVGImport,
  "question-mark": questionMark as SVGImport,
  "question-mark-white": questionMarkWhite as SVGImport,
  sidebar: sidebar as SVGImport,
  "sidebar-white": sidebarWhite as SVGImport,
  acorn: acorn as SVGImport,
  "acorn-white": acornWhite as SVGImport,
  padlock: padlock as SVGImport,
  "ai-lesson": aiLesson as SVGImport,
};
